import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from 'jquery';

jQuery(document).ready(function($){
	function morphDropdown( element ) {
		this.element = element;
		this.mainNavigation = this.element.find('.main-nav');
		this.mainNavigationItems = this.mainNavigation.find('.has-dropdown');
		this.dropdownList = this.element.find('.dropdown-list');
		this.dropdownWrappers = this.dropdownList.find('.dropdown');
		this.dropdownItems = this.dropdownList.find('.content');
		this.dropdownBg = this.dropdownList.find('.bg-layer');
		this.mq = this.checkMq();
		this.bindEvents();
	}

	morphDropdown.prototype.checkMq = function() {
		//check screen size
		var self = this;
		return window.getComputedStyle(self.element.get(0), '::before').getPropertyValue('content').replace(/'/g, "").replace(/"/g, "").split(', ');
	};

	morphDropdown.prototype.bindEvents = function() {
		var self = this;
		//hover over an item in the main navigation
		this.mainNavigationItems.mouseenter(function(event){
			//hover over one of the nav items -> show dropdown
			self.showDropdown($(this));
		}).mouseleave(function(){
			setTimeout(function(){
				//if not hovering over a nav item or a dropdown -> hide dropdown
				if( self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.dropdown-list:hover').length == 0 ) self.hideDropdown();
			}, 50);
		});

		//hover over the dropdown
		this.dropdownList.mouseleave(function(){
			setTimeout(function(){
				//if not hovering over a dropdown or a nav item -> hide dropdown
				(self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.dropdown-list:hover').length == 0 ) && self.hideDropdown();
			}, 50);
		});

		//click on an item in the main navigation -> open a dropdown on a touch device
		this.mainNavigationItems.on('touchstart', function(event){
			var selectedDropdown = self.dropdownList.find('#'+$(this).data('content'));
			if( !self.element.hasClass('is-dropdown-visible') || !selectedDropdown.hasClass('active') ) {
				event.preventDefault();
				self.showDropdown($(this));
			}
		});

		//on small screens, open navigation clicking on the menu icon
		this.element.on('click', '.nav-trigger', function(event){
			event.preventDefault();
			self.element.toggleClass('nav-open');
			$('body').toggleClass('scroll-locked');
			$('.bottomNav').toggleClass('open');
			$('.bottomNav').toggle();
		});
	};

	morphDropdown.prototype.showDropdown = function(item) {
		this.mq = this.checkMq();
		if( this.mq == 'desktop') {
			var self = this;
			var selectedDropdown = this.dropdownList.find('#'+item.data('content')),
				selectedDropdownHeight = selectedDropdown.children('.content').innerHeight(),
				selectedDropdownWidth = selectedDropdown.children('.content').innerWidth(),
				selectedDropdownLeft = item.offset().left + item.innerWidth()/2 - selectedDropdownWidth/2;

			//update dropdown position and size
			this.updateDropdown(selectedDropdown, parseInt(selectedDropdownHeight), selectedDropdownWidth, parseInt(selectedDropdownLeft));
			//add active class to the proper dropdown item
			this.element.find('.active').removeClass('active');
			selectedDropdown.addClass('active').removeClass('move-left move-right').prevAll().addClass('move-left').end().nextAll().addClass('move-right');
			item.addClass('active');
			//show the dropdown wrapper if not visible yet
			if( !this.element.hasClass('is-dropdown-visible') ) {
				setTimeout(function(){
					self.element.addClass('is-dropdown-visible');
				}, 10);
			}
		}
	};

	morphDropdown.prototype.updateDropdown = function(dropdownItem, height, width, left) {
		this.dropdownList.css({
		    '-moz-transform': 'translateX(' + left + 'px)',
		    '-webkit-transform': 'translateX(' + left + 'px)',
			'-ms-transform': 'translateX(' + left + 'px)',
			'-o-transform': 'translateX(' + left + 'px)',
			'transform': 'translateX(' + left + 'px)',
			'width': width+'px',
			'height': height+'px'
		});

		this.dropdownBg.css({
			'-moz-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
		    '-webkit-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
			'-ms-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
			'-o-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
			'transform': 'scaleX(' + width + ') scaleY(' + height + ')'
		});
	};

	morphDropdown.prototype.hideDropdown = function() {
		this.mq = this.checkMq();
		if( this.mq == 'desktop') {
			this.element.removeClass('is-dropdown-visible').find('.active').removeClass('active').end().find('.move-left').removeClass('move-left').end().find('.move-right').removeClass('move-right');
		}
	};

	morphDropdown.prototype.resetDropdown = function() {
		this.mq = this.checkMq();
		if( this.mq == 'mobile') {
			this.dropdownList.removeAttr('style');
		}
	};

	var morphDropdowns = [];
	if( $('.cd-morph-dropdown').length > 0 ) {
		$('.cd-morph-dropdown').each(function(){
			//create a morphDropdown object for each .cd-morph-dropdown
			morphDropdowns.push(new morphDropdown($(this)));
		});

		var resizing = false;

		//on resize, reset dropdown style property
		updateDropdownPosition();
		$(window).on('resize', function(){
			if( !resizing ) {
				resizing =  true;
				(!window.requestAnimationFrame) ? setTimeout(updateDropdownPosition, 300) : window.requestAnimationFrame(updateDropdownPosition);
			}
		});

		function updateDropdownPosition() {
			morphDropdowns.forEach(function(element){
				element.resetDropdown();
			});

			resizing = false;
		};
	}
});
	var swiper = new Swiper('.testimonials', {
	  loop: 1,
	  autoplay: {
	    delay: 5000,
	    disableOnInteraction: false,
	  },
	  navigation: {
	    nextEl: '.testiCarousel .swiper-button-next',
	    prevEl: '.testiCarousel .swiper-button-prev',
	  },
	});
	function productSpace() {
		var maxHeight = 0;
		$('.row .product ul li a img').each(function(){
		var thisH = $(this).height();
		if (thisH > maxHeight) { maxHeight = thisH; }
		});
		$('.row .product ul li a img').each(function() {
			if($(this).height() < maxHeight) {
				let temp = maxHeight - $(this).height();
				let space = temp/2;
				let oldBot = parseInt( $(this).css("marginBottom") )
				let oldTop = parseInt( $(this).css("marginTop") )
				$(this).css("margin-top",  space + oldTop + "px");
				$(this).css("margin-bottom",  space + oldBot + "px");
			}
		});
	}
	productSpace();
	function productSale() {
		let sale = $('.row .product ul li .onsale');
		let price = $('.row .product ul li .price');
		let prevPrice = $('.row .product ul li .price del')
		let newPrice = $('.row .product ul li .price ins')
		price.each(function(index) {
			if(parseInt(prevPrice.eq(index).text().substring(1)) != "") {
				// return 100 - (salePrice * 100 / listPrice);
				let saleNum = 100 - (parseInt(newPrice.eq(index).text().replace("€","")) * 100 / parseInt(prevPrice.eq(index).text().replace("€","")) );
				let finalPer = (Math.round(saleNum / 10) * 10);
				sale.eq(index).attr('data-percent',finalPer);
			}
		})
		let sale1 = $('.single-product .product .onsale');
		let price1 = $('.single-product .product .price');
		let prevPrice1 = $('.single-product .product .price del')
		let newPrice1 = $('.single-product .product .price ins')
		price1.each(function(index) {
			if(parseInt(prevPrice1.eq(index).text().substring(1)) != "") {
				// return 100 - (salePrice * 100 / listPrice);
				let saleNum1 = 100 - (parseInt(newPrice1.eq(index).text().replace("€","")) * 100 / parseInt(prevPrice1.eq(index).text().replace("€","")) );
				let finalPer1 = (Math.round(saleNum1 / 10) * 10);
				sale1.eq(index).attr('data-percent',finalPer1);
			}
		})
		let sale2 = $('.recentlyViewed .product .onsale');
		let price2 = $('.recentlyViewed .product .price');
		let prevPrice2 = $('.recentlyViewed .product .price del')
		let newPrice2 = $('.recentlyViewed .product .price ins')
		price2.each(function(index) {
			if(parseInt(prevPrice2.eq(index).text().substring(1)) != "") {
				// return 100 - (salePrice * 100 / listPrice);
				let saleNum2 = 100 - (parseInt(newPrice2.eq(index).text().replace("€","")) * 100 / parseInt(prevPrice2.eq(index).text().replace("€","")) );
				let finalPer2 = (Math.round(saleNum2 / 10) * 10);
				sale2.eq(index).attr('data-percent',finalPer2);
			}
		})
	}
	productSale();
	$('#search').click(function(e) {
		e.preventDefault();
		$('#ajaxsearchlite1').toggle(400);
		if($('.bottomNav').hasClass('open') == true) {
			$('.bottomNav').removeClass('open');
			$('.bottomNav').toggle();
			$('.cd-morph-dropdown').removeClass('nav-open');
			$('body').removeClass('scroll-locked');
		}
	})
	function toggler() {
		$('#elementor-menu-cart__toggle_button').click(function(e){window.location.assign("https://zdravie.kolovratok.sk/cart");})
	}
	setTimeout(toggler,1500)
	$(window).bind("load", function() {
		$('.product .products li a h2').each(function() {
			if($(this).text().length > 50) {
				$(this).html($(this).text().substring(0,50) + "...")
			}
		})
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		var isFirefox = navigator.userAgent.search("Firefox");
		if(isSafari != false || isFirefox != "-1") {
			$('.cartWrap').css("top","-4px")
		}
		function tabHeight() {
			var highest = null;
			var hi = 0;
			$(".swipeWrap .productCarousel .swiper-slide a").each(function(){
			var h = $(this).innerHeight();
			if(h > hi){
				hi = h;
				highest = $(this).innerHeight();
				console.log(highest);  
			}    
			});
			$('.productCarousel').css("height",highest + 50 + "px")
			$('.productCarousel .swiper-slide a').css("height",highest + "px")
		}
		setTimeout(tabHeight,1500);
		function prodHeight() {
			var highest = null;
			var hi = 0;
			$(".shopWrap .product .products li").each(function(){
			var h = $(this).innerHeight();
			if(h > hi){
				hi = h;
				highest = $(this).innerHeight();
				console.log(highest);  
			}    
			});
			$('.shopWrap .product .products li').css("height",highest + "px")
			$('.shopWrap .product .products li .woocommerce-LoopProduct-link').css("min-height",highest + "px")
		}
		setTimeout(prodHeight,250);
		$('.recentlyViewed ul li a h2').each(function() {
			if($(this).text().length > 50) {
				$(this).html($(this).text().substring(0,50) + "...")
			}
		})
	})
	$('body').on('blur change', '.form-row span input', function(){
		var wrapper = $(this).closest('.form-row');
		// you do not have to removeClass() because Woo do it in checkout.js
		if( $(this).val() == "" ) { // check if contains numbers
			wrapper.addClass('woocommerce-invalid'); // error
			$('#place_order').attr('disabled','disabled');
		} else {
			wrapper.addClass('woocommerce-validated'); // success
			$('#place_order').removeAttr('disabled');
		}
	});
	$('<button class="register" type="button">Register</button>').insertAfter($('.lost_password'));
	$('.register').click(function(e) {
		e.preventDefault();
		$('.woocommerce-account .col2-set .col-1').fadeOut('fast');
		$('.woocommerce-account .col2-set .col-2').fadeIn('fast');
	})
	$('#billing_companys_field input').each(function() {
		$(this).change(function(){
			console.log($(this).val());
			if($(this).val() == "company_purchase") {
				$('#billing_comapny_vat_field').fadeIn();
				$('#billing_company_name_field').fadeIn();
				$('#billing_companyid_field').fadeIn();
			}
			else {
				$('#billing_comapny_vat_field').fadeOut();
				$('#billing_company_name_field').fadeOut();
				$('#billing_companyid_field').fadeOut();
			}
		});
	})
	var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	var isFirefox = navigator.userAgent.search("Firefox");
	$('#billing_shipping_adr_field input').each(function() {
		$(this).change(function(){
			console.log($(this).val());
			if($(this).val() == "new_address") {
				$('#additional_address_1_field').fadeIn();
				$('#additional_address_3_field').fadeIn();
				$('#additional_address_4_field').fadeIn();
			}
			else {
				$('#additional_address_1_field').fadeOut();
				$('#additional_address_3_field').fadeOut();
				$('#additional_address_4_field').fadeOut();
			}
		});
	})
	if($(window).width() < 1199) {
		$('.clickBlock').each(function() {
			$(this).attr('href', '#');
			$(this).attr("disabled","disabled");
			$(this).click(function(e) {
				e.preventDefault();
				return false;
			})
		})
	}
	function updatePrice() {
		if($('p.price').first().html() != $('.composite_price .price').html()) {
			$('p.price').first().html($('.composite_price .price').html())
		}
	}
	$('.composite_component select').change(function () {
		console.log('change')
		setTimeout(updatePrice, 500);
	}) 	
let menuLink = $('.dropdown-list a.label');
let check = 0;
if($(window).width() < 1280) {
	check ++;
	if(check == 1) {
		menuLink.each(function (index){
			$(this).click(function (e) {
				console.log('check')
				console.log(index)
				if($('.dropdown-list .dropdown').eq(index).find('ul').children().length > 0) {
					console.log('true')
					e.preventDefault();
					if($('.dropdown-list .dropdown').eq(index).children().last().is(':visible') == false) {
						$('.dropdown-list .dropdown').eq(index).children().last().fadeIn();
					}
					else {
						$('.dropdown-list .dropdown').eq(index).children().last().fadeOut();
					}
				}
			});
		});
	}
}
$(window).resize(function() {
	check++;
	if(check == 1) {
		if($(window).width() < 1280) {
			menuLink.each(function (index){
				$(this).click(function (e) {
					console.log('check')
					console.log(index)
					if($('.dropdown-list .dropdown').eq(index).find('ul').children().length > 0) {
						console.log('true')
						e.preventDefault();
						if($('.dropdown-list .dropdown').eq(index).children().last().is(':visible') == false) {
							$('.dropdown-list .dropdown').eq(index).children().last().fadeIn();
						}
						else {
							$('.dropdown-list .dropdown').eq(index).children().last().fadeOut();
						}
					}
				});
			});
		}
	}
});
// $(document).ready(function() {
//     $('.mockupHero .row .col-12').first().children().addClass('init');
//     $('.productTeaser .col-12').attr({
//        "data-aos": "animat-text",
//     })
// 	$('.mockupTabsTimed .heading-2').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupProTabs .heading-2').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupTabs .text .heading-2').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupTabs .text .copy-1').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupSteps .col-12').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupBGTabs .col-12').first().attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.textTeaser .col-12').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupBoxes .texts').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.accordions .heading-2').attr({
// 		"data-aos": "animat-text",
// 	})
// 	$('.mockupCTA .copy').attr({
// 		"data-aos": "animat-text",
// 	})
//     AOS.init({
//       disable: 'phone',
//       once: true,
//       offset: 160,
//     });
// })
let faq = $('.accordions .accordion');
faq.each(function(index) {
	let temp = $(this);
	$(this).click(function (e) {
		e.preventDefault();
		temp.toggleClass('active');
		if(temp.children().first().children().last().text() == "+") {
			temp.children().first().children().last().text("-")
		}
		else {
			temp.children().first().children().last().text("+")
		}
	})
})
$(document).ready(function() {
	var swiper = new Swiper(".heroSlider", {
        pagination: {
          el: ".heroSlider .swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + "</span>";
          },
        },
    });
	if($(window).width() < 768) {
		var swipe1 = new Swiper(".productCarousel", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			slidesPerView: 2,
			slidesPerGroup: 2,
			loop: true,
			spaceBetween: 20,
		});
	}
	else {
		if($(window).width() > 768 && $(window).width() < 1024) {
			var swipe1 = new Swiper(".productCarousel", {
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				slidesPerView: 3,
				slidesPerGroup: 3,
				loop: true,
				spaceBetween: 20,
			});
		}
	}
	if($(window).width() > 1023) {
		var swipe1 = new Swiper(".productCarousel", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			slidesPerView: 4,
			slidesPerGroup: 4,
			loop: true,
			spaceBetween: 20,
		});
	}
	$(window).resize(function() {
		swipe1 = new Swiper(".productCarousel", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			slidesPerView: 2,
			slidesPerGroup: 2,
			loop: true,
			spaceBetween: 20,
			breakpoints: {
				768: {
				  slidesPerView: 3,
				  slidesPerGroup: 3,
				},
				1024: {
				  slidesPerView: 4,
				  slidesPerGroup: 4,
				},
			},
		});
	})
	let prodBtn = $('.skProdTabs .tabs button');
	let prodSlider = $('.skProdTabs .swipeWrap');
	prodBtn.each(function(index) {
		$(this).click(function(e) {
			e.preventDefault();
			prodBtn.removeClass('active');
			prodSlider.removeClass('active');
			prodSlider.hide();
			$(this).addClass('active');
			prodSlider.eq(index).fadeIn();
			prodSlider.eq(index).addClass('active');
			swipe1 = new Swiper(".productCarousel", {
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				slidesPerView: 2,
				slidesPerGroup: 2,
				loop: true,
				spaceBetween: 20,
				breakpoints: {
					768: {
					  slidesPerView: 3,
					  slidesPerGroup: 3,
					},
					1024: {
					  slidesPerView: 4,
					  slidesPerGroup: 4,
					},
				},
			});
		})
	})
	prodBtn.first().addClass('active');
	prodSlider.first().addClass('active');
	swipe1 = new Swiper(".productCarousel", {
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		slidesPerView: 4,
		loop: true,
		spaceBetween: 20,
	});
	let scrollTop = $('#scrollTop');
	scrollTop.click(function(e) {
		e.preventDefault();
		$("html, body").animate({scrollTop: 0});
	})
	$('.main-breadcrumbs span span a').first().html('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="13px"> <path fill-rule="evenodd"  fill="rgb(204, 85, 85)" d="M0.563,8.028 L1.689,8.028 L1.689,12.533 C1.689,12.798 1.907,13.016 2.172,13.016 L5.470,13.016 C5.735,13.016 5.952,12.798 5.952,12.533 L5.952,9.235 L7.964,9.235 L7.964,12.533 C7.964,12.798 8.181,13.016 8.446,13.016 L11.583,13.016 C11.849,13.016 12.066,12.798 12.066,12.533 L12.066,8.028 L13.433,8.028 C13.626,8.028 13.803,7.908 13.876,7.730 C13.948,7.554 13.908,7.345 13.771,7.208 L7.481,0.829 C7.296,0.644 6.990,0.636 6.805,0.821 L0.233,7.199 C0.089,7.336 0.048,7.545 0.121,7.730 C0.193,7.915 0.370,8.028 0.563,8.028 Z"/></svg>');
	$('#breadcrumbs span span a').first().html('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="13px"> <path fill-rule="evenodd"  fill="rgb(204, 85, 85)" d="M0.563,8.028 L1.689,8.028 L1.689,12.533 C1.689,12.798 1.907,13.016 2.172,13.016 L5.470,13.016 C5.735,13.016 5.952,12.798 5.952,12.533 L5.952,9.235 L7.964,9.235 L7.964,12.533 C7.964,12.798 8.181,13.016 8.446,13.016 L11.583,13.016 C11.849,13.016 12.066,12.798 12.066,12.533 L12.066,8.028 L13.433,8.028 C13.626,8.028 13.803,7.908 13.876,7.730 C13.948,7.554 13.908,7.345 13.771,7.208 L7.481,0.829 C7.296,0.644 6.990,0.636 6.805,0.821 L0.233,7.199 C0.089,7.336 0.048,7.545 0.121,7.730 C0.193,7.915 0.370,8.028 0.563,8.028 Z"/></svg>');
	$('.yoast-breadcrumbs span span a').first().html('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="13px"> <path fill-rule="evenodd"  fill="rgb(204, 85, 85)" d="M0.563,8.028 L1.689,8.028 L1.689,12.533 C1.689,12.798 1.907,13.016 2.172,13.016 L5.470,13.016 C5.735,13.016 5.952,12.798 5.952,12.533 L5.952,9.235 L7.964,9.235 L7.964,12.533 C7.964,12.798 8.181,13.016 8.446,13.016 L11.583,13.016 C11.849,13.016 12.066,12.798 12.066,12.533 L12.066,8.028 L13.433,8.028 C13.626,8.028 13.803,7.908 13.876,7.730 C13.948,7.554 13.908,7.345 13.771,7.208 L7.481,0.829 C7.296,0.644 6.990,0.636 6.805,0.821 L0.233,7.199 C0.089,7.336 0.048,7.545 0.121,7.730 C0.193,7.915 0.370,8.028 0.563,8.028 Z"/></svg>');
	$('.product-category h2').each(function() {
		if($(this).text() == '\n\t\t\tUncategorized (2)\t\t') {
			$(this).parent().parent().remove();
		}
	});
	$('.plus').on('click', function (e) {

	  var val = parseInt($(this).prev('input').val());

	  $(this).prev('input').val(val + 1);
	  $('.actions button').attr('aria-disabled','false');
	  $('.actions button').removeAttr('disabled')
	});
	$('.minus').on('click', function (e) {
	  var val = parseInt($(this).next('input').val());
	  if (val !== 0) {
	    $(this).next('input').val(val - 1);
	  }
	  $('.actions button').attr('aria-disabled','false');
	  $('.actions button').removeAttr('disabled')
	});
	$( ".product ul li .button" ).append('<svg xmlns="http://www.w3.org/2000/svg" width="4.525" height="6.959" viewBox="0 0 4.525 6.959"><path id="_copy_9" data-name=">> copy 9" d="M1009.6,2598.574a1.248,1.248,0,0,1-.144.568,1.808,1.808,0,0,1-.45.545q-1.222,1.05-1.925,1.6a10.347,10.347,0,0,1-.836.617,1.277,1.277,0,0,1-.234.1.732.732,0,0,1-.5-.012.7.7,0,0,1-.228-.139.653.653,0,0,1-.156-.22.731.731,0,0,1-.058-.3.822.822,0,0,1,.084-.371,1.679,1.679,0,0,1,.285-.383l1.009-1.3a1.172,1.172,0,0,0,.305-.708.823.823,0,0,0-.075-.336,1.911,1.911,0,0,0-.231-.371l-1.009-1.264a2.23,2.23,0,0,1-.274-.415.861.861,0,0,1-.1-.38.77.77,0,0,1,.061-.316.684.684,0,0,1,.161-.229.645.645,0,0,1,.228-.136.786.786,0,0,1,.259-.044.821.821,0,0,1,.236.035.684.684,0,0,1,.225.122l2.761,2.227a1.45,1.45,0,0,1,.594,1.108Z" transform="translate(-1005.079 -2595.083)" fill="#fff"></path></svg>')
	$( ".product ul li .button" ).each(function(index) {
		$( '<a href="' + $(this).parent().children().first().attr('href') +'" class="button first-btn product_type_simple add_to_cart_button ajax_add_to_cart">Detaily <svg xmlns="http://www.w3.org/2000/svg" width="4.525" height="6.959" viewBox="0 0 4.525 6.959"><path id="_copy_9" data-name=">> copy 9" d="M1009.6,2598.574a1.248,1.248,0,0,1-.144.568,1.808,1.808,0,0,1-.45.545q-1.222,1.05-1.925,1.6a10.347,10.347,0,0,1-.836.617,1.277,1.277,0,0,1-.234.1.732.732,0,0,1-.5-.012.7.7,0,0,1-.228-.139.653.653,0,0,1-.156-.22.731.731,0,0,1-.058-.3.822.822,0,0,1,.084-.371,1.679,1.679,0,0,1,.285-.383l1.009-1.3a1.172,1.172,0,0,0,.305-.708.823.823,0,0,0-.075-.336,1.911,1.911,0,0,0-.231-.371l-1.009-1.264a2.23,2.23,0,0,1-.274-.415.861.861,0,0,1-.1-.38.77.77,0,0,1,.061-.316.684.684,0,0,1,.161-.229.645.645,0,0,1,.228-.136.786.786,0,0,1,.259-.044.821.821,0,0,1,.236.035.684.684,0,0,1,.225.122l2.761,2.227a1.45,1.45,0,0,1,.594,1.108Z" transform="translate(-1005.079 -2595.083)" fill="#fff"></path></svg> </a>' ).insertBefore( $(this ) )
	})
	if($('.read-more').length > 0) {
		$('.read-more').each(function() {
			$(this).text("Čítajte viac");
		})
	}
})